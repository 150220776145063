<template>
  <dash-page-new
      :title="$store.state.settings.pageData.title || $t('Structure')"
      :root="selectedEntityType ? $t(PLURIZE_WORD(CAPITALIZA_FIRST_CHAR(selectedEntityType))) : $store.state.settings.pageData.root || $t('Dashboard')"
      :subtitle="selectedEntityName || $t('Structure')"
      :root-location="businessDashLink(selectedEntityType ? `structure/${PLURIZE_WORD(selectedEntityType)}` : '' )"
      icon="mdi-sitemap-outline"
      :side-navigation="navigationFiltered"
      :navigation-route="$store.state.settings.pageData.navigationRoute || 'structure' "
      :navigation-return-route="selectedEntityType ? `structure/${PLURIZE_WORD(selectedEntityType)}` : '' "
      @root="handleReturnAction"
  >

    <template #header_action>

      <div class="d-flex align-center">

        <ft-select
            v-model="$store.state.structure.selectedLang"
            :items="langsSelect"
        >
          <template #default="{text}">
            <v-hover #default="{hover}">
              <v-sheet
                  :color="hover ? wsLIGHTCARD : null"
                  class="mr-3 px-1 pl-3 d-flex align-center wsRoundedLight"
                  style="transition: all 0.2s ease"
                  height="40"
                  v-ripple
              >
                <h4 >{{ text }}
                  <v-icon>mdi-menu-down</v-icon>
                </h4>
              </v-sheet>
            </v-hover>


          </template>

        </ft-select>

        <ft-select
            v-if="($store.state.settings.pageData.headerAction || !!$store.state.settings.pageData.headerDropdownActions) && ($store.state.settings.pageData.headerActionCondition === null || $store.state.settings.pageData.headerActionCondition)"
            :items="$store.state.settings.pageData.headerDropdownActions || []">
          <ws-button
              @click="!$store.state.settings.pageData.headerDropdownActions ? $store.state.settings.pageData.headerAction() : () => {}"
              :label-raw="$store.state.settings.pageData.headerActionText"
              :click-stop="!$store.state.settings.pageData.headerDropdownActions"
              :icon="!!$store.state.settings.pageData.headerDropdownActions ? 'mdi-menu-down' : null"
          />
        </ft-select>

      </div>


    </template>

    <template #default>
        <router-view></router-view>
    </template>

  </dash-page-new>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DashStructure",
  data() {
    return {
      items : [
        { name : 'Information technologies faculty' }
      ],
    }
  },
  computed : {
    ...mapState('structure' , [
      'selectedEntityType' ,
      'selectedEntityId',
      'selectedEntityName',
      'returnEntityType',
      'returnEntityId',
      'returnEntityName',
    ]),
    ...mapState('dashboard' , [
      'pageNavigationReturnPressTrigger' ,
    ]),


    returnRoute() {
      let route = ''
      if ( this.returnEntityType.length > 0 ) {
        let entity =  this.returnEntityType[this.returnEntityType.length - 1]
        route = 'structure'
        if ( entity ) {
          route += '/' + this.PLURIZE_WORD(entity)
        }
      }
      return route
    },
    langsSelect() {
      return [
        { text : 'UA' , value : 'ua' },
        { text : 'EN' , value : 'en' }
      ]
    },
    navigationFiltered() {
      let items = this.navigation

      items = items.filter( el =>
          (el.requireEntity && !!this.selectedEntityType) ||
          (el.entity && !this.returnEntityType.includes(el.entity) && this.getEntityHierarcyIndex(el.entity) > this.getEntityHierarcyIndex(this.selectedEntityType)  ) ||
          (el.parent && el.parent.includes(this.selectedEntityType) )
      )

      return items
    },
    navigation() {
      return  [
        // { text : this.$t('Rectorate')      ,  value : 'rectorate'       , path : 'editor'      , icon : 'mdi-account-tie' ,  permission:'MODULE_WESTUDY__EDITOR', },
        // { text : this.$t('ScienceCouncil') ,  value : 'science_council' , path : 'editor'      , icon : 'mdi-school' ,  permission:'MODULE_WESTUDY__EDITOR', },

        // { text : this.$t('Description') ,
        //   value : 'description'      ,
        //   path : 'description'    ,
        //   icon : 'mdi-table-of-contents' ,
        //   permission:'MODULE_WESTUDY__EDITOR',
        //   requireEntity : true
        // },
        { text : this.$t('Institutes')     ,
          value : 'editor'         ,
          path : 'institutes'    ,
          icon : 'mdi-table-of-contents' ,
          permission:'MODULE_WSU-STRUCTURE',
          entity : 'institute'
        },
        { text : this.$t('Faculties')      ,
          value : 'faculties'      ,
          path : 'faculties'     ,
          icon : 'mdi-image-text'        ,
          permission:'MODULE_WSU-STRUCTURE',
          entity : 'faculty'
        },
        { text : this.$t('Departments')    ,
          value : 'departments'    ,
          path : 'departments'   ,
          icon : 'mdi-account-multiple'  ,
          permission:'MODULE_WSU-STRUCTURE',
          entity : 'department'
        },
        { text : this.$t('EducationPrograms')    ,
          value : 'specialties'    ,
          path : 'specialties'   ,
          icon : 'mdi-school-outline'  ,
          permission:'MODULE_WSU-STRUCTURE',
          entity : 'specialty'
        },
        { text : this.$t('Disciplines') ,
          value : 'disciplines'    ,
          path : 'disciplines'   ,
          icon : 'mdi-school-outline'  ,
          permission:'MODULE_WSU-STRUCTURE',
          parent : ['specialty' , 'department']
        },
        { text : this.$t('Teachers')    ,
          value : 'teachers'    ,
          path : 'teachers'   ,
          icon : 'mdi-account-tie'  ,
          permission:'MODULE_WSU-STRUCTURE',
          parent : ['department']
        },
        // { text : this.$t('Workload')    ,
        //   value : 'workload'    ,
        //   path : 'workload'   ,
        //   icon : 'mdi-timetable'  ,
        //   permission:'MODULE_WSU-STRUCTURE',
        //   parent : ['department']
        // },
        { text : this.$t('Groups') ,
          value : 'groups'    ,
          path : 'groups'   ,
          icon : 'mdi-school-outline'  ,
          permission:'MODULE_WSU-STRUCTURE',
          parent : ['specialty']
        },
      ]
    },
    entitiesHierarcyArray() {
      return this.navigation.filter(el => el.entity ).map(el => el.entity)
    },

    headers() {
      return [
        { text : this.$t('Name') , value : 'name' }
      ]
    }
  },
  watch : {
    pageNavigationReturnPressTrigger() {
      this.handleReturnAction()
    }
  },
  methods : {
    getEntityHierarcyIndex(type) {
      return this.entitiesHierarcyArray.findIndex(el => el === type)
    },
    handleReturnAction() {
      this.$store.state.structure.selectedEntityType = this.$store.state.structure.returnEntityType[this.$store.state.structure.returnEntityType.length - 1] || null
      this.$store.state.structure.selectedEntityId = this.$store.state.structure.returnEntityId[this.$store.state.structure.returnEntityId.length - 1] || null
      this.$store.state.structure.selectedEntityName = this.$store.state.structure.returnEntityName[this.$store.state.structure.returnEntityName.length - 1] || null

      this.$store.state.structure.returnEntityType.pop()
      this.$store.state.structure.returnEntityId.pop()
      this.$store.state.structure.returnEntityName.pop()

    }
  },
  beforeMount() {


    if ( this.$route.query && this.$route.query.uuid && this.$route.query.name && this.$route.query.entity ) {
      this.$store.state.structure.selectedEntityType = this.$route.query.entity
      this.$store.state.structure.selectedEntityId = this.$route.query.uuid
      this.$store.state.structure.selectedEntityName = this.$route.query.name

      this.$store.state.structure.returnEntityType = [null]
      this.$store.state.structure.returnEntityId = [null]
      this.$store.state.structure.returnEntityName = [null]

    }



    // this.$router.push(this.businessDashLink('structure/institutes'))

  }
}
</script>

<style scoped>

</style>